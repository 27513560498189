import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="bg-[url('./assets/picklebig.jpg')] bg-right-top bg-cover w-screen h-screen fixed">
      <div className="bg-[url('./assets/pickleball.png')] bg-contain w-72 h-44 z-50 fixed bottom-1/2 " ></div>
    </div>
  );
}

export default App;
